import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'payment-method-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new payment method'))+" ")]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'payment-method-update', params: { code: item.code } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(item.enabled ? 'done' : 'close'))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'payment-method-update', params: { code: item.code } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(!_vm.PaymentMethodCodes.includes(item.code) && _vm.canDelete)?_c('payment-method-action-delete',{attrs:{"value":item,"icon":""},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}}):_vm._e()]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Payment methods') },false))}
var staticRenderFns = []

export { render, staticRenderFns }